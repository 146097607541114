.text-left {
    text-align: start;
}

.text-right {
    text-align: end;
}

.text-center {
    text-align: center;
}

.text-no-wrap {
    white-space: nowrap;
}

.text-success {
    color: var(--success-color);
}

.text-danger {
    color: var(--red);
}

.text-white {
    color: var(--white);
}

.text-gray {
    color: var(--gray-color);
}

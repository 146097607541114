/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");

:root {
    --page-header-bgColor: #242e42;
    --page-header-bgColor-hover: #1d2636;
    --page-header-txtColor: #dde9f8;
    --page-header-headingColor: #7889a4;
    --page-header-width: 220px;
    --page-content-bgColor: #f0f1f6;
    --page-content-txtColor: #171616;
    --page-content-blockColor: #fff;
    --white: #fff;
    --black: #333;
    --blue: #00b9eb;
    --red: #ec1848;
    --border-radius: 4px;
    --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
    --switch-bgLightModeColor: #87cefa;
    --switch-sunColor: gold;
    --switch-moonColor: #f4f4f4;
    --switch-bgDarkModeColor: #1f1f27;
    --inner-padding: 30px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul.list-style-none {
    list-style: none;
}

a,
button {
    color: inherit;
}

a {
    text-decoration: none;
}

button {
    background: none;
    cursor: pointer;
}

input {
    -webkit-appearance: none;
}

[type="checkbox"] {
    position: absolute;
    left: -9999px;
}

label {
    cursor: pointer;
}

button,
input {
    border: none;
}

body {
    font: 16px/1.5 "Lato", sans-serif;
}


/* HEADER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding-top: 20px;
    width: var(--page-header-width);
    color: var(--page-header-txtColor);
    background: var(--page-header-bgColor);
}

/*In case you prefer an absolutely positioned header that covers the full page height, add these styles*/
/*body {
  position: relative;
}

.page-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}*/

/*remove these styles*/
/*.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}*/

.page-header nav {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.page-header .logo {
    display: block;
    margin: 15px;
}

.page-header .logo img {
    width: 100%;
    fill: var(--white);
}

.page-header .toggle-mob-menu {
    display: none;
    margin-left: 5px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    padding: 4px;
}

.page-header .toggle-mob-menu svg {
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
}

.page-header .admin-menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 35px;
}

.page-header .admin-menu li:nth-last-child(2) {
    margin-bottom: 35px;
}

.page-header .admin-menu li:last-child {
    margin-top: auto;
    margin-bottom: 20px;
}

.page-header .admin-menu li > * {
    width: 100%;
    padding: 12px 15px;
}

.page-header .admin-menu .switcher {
    display: inline-block;
    width: auto;
}

.page-header .admin-menu .menu-heading h3 {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 12px;
    margin-top: 12px;
    color: var(--page-header-headingColor);
}

.page-header .admin-menu svg {
    width: 20px;
    height: 20px;
    fill: var(--page-header-txtColor);
    margin-right: 10px;
}

.page-header .admin-menu a,
.page-header .admin-menu button {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
}

.page-header .admin-menu a:hover,
.page-header .admin-menu a:focus,
.page-header .admin-menu button:hover,
.page-header .admin-menu button:focus {
    background: var(--page-header-bgColor-hover);
    color: var(--blue);
    outline: none;
}

.page-header .admin-menu a:hover svg,
.page-header .admin-menu a:focus svg,
.page-header .admin-menu button:hover svg,
.page-header .admin-menu button:focus svg {
    fill: var(--blue);
}


/* PAGE CONTENT STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-content {
    position: relative;
    left: var(--page-header-width);
    width: calc(100% - var(--page-header-width));
    min-height: 100vh;
    padding: 30px;
    color: var(--page-content-txtColor);
    background: var(--page-content-bgColor);
}

.search-and-user {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 50px;
    align-items: center;
    background: var(--page-content-bgColor);
    margin-bottom: 30px;
}

.search-and-user form {
    position: relative;
}

.search-and-user [type="search"] {
    width: 100%;
    height: 50px;
    font-size: 1.5rem;
    padding-left: 15px;
    background: var(--page-content-blockColor);
    color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.search-and-user ::placeholder {
    color: var(--page-content-txtColor);
}

.search-and-user form svg {
    width: 26px;
    height: 26px;
    fill: var(--page-content-txtColor);
}

.search-and-user form button {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.search-and-user .admin-profile {
    display: flex;
    align-items: center;
}

.search-and-user .admin-profile .greeting {
    margin: 0 10px 0 20px;
}

.search-and-user .admin-profile svg {
    width: 30px;
    height: 30px;
}

.search-and-user .admin-profile .notifications {
    position: relative;
}

.search-and-user .admin-profile .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 10px;
    color: var(--white);
    background: var(--red);
}

.page-content .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
}

.page-content .grid article {
    display: flex;
    flex-direction: column;
    background: var(--page-content-blockColor);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: var(--inner-padding);
}


/* MQ RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 767px) {
    .page-header,
    .page-content {
        position: static;
        width: 100%;
    }

    .page-header {
        padding: 10px;
    }

    .page-header nav {
        flex-direction: row;
    }

    .page-header .logo {
        margin: 0;
    }

    .page-header .logo {
        width: 83px;
        height: 35px;
    }

    .page-header .logo img {
        width: auto;
        height: 35px;
    }

    .page-header .toggle-mob-menu {
        display: block;
    }

    .page-header .admin-menu {
        position: absolute;
        left: 3px;
        right: 3px;
        top: 57px;
        margin-top: 0;
        z-index: 2;
        border-radius: var(--border-radius);
        background: var(--page-header-bgColor);
        visibility: hidden;
        opacity: 0;
        transform: scale(0.95);
        transition: all 0.2s;
    }

    .page-header .admin-menu li:nth-last-child(2) {
        margin-bottom: 12px;
    }

    /*.page-header .admin-menu li:last-child button,*/
    .search-and-user .admin-profile .greeting {
        display: none;
    }

    .page-content {
        /*min-height: 0;*/
        padding: 10px;
    }

    .page-content .grid {
        grid-gap: 10px;
    }

    .search-and-user {
        position: absolute;
        left: 131px;
        top: 10px;
        padding: 0;
        grid-column-gap: 5px;
        width: calc(100% - 141px);
        border-radius: var(--border-radius);
        background: transparent;
    }

    .search-and-user [type="search"] {
        font-size: 1rem;
        height: 35px;
    }

    .search-and-user form svg {
        width: 18px;
        height: 18px;
    }

    .search-and-user .admin-profile svg {
        fill: var(--white);
    }

    .grid article header .actions {
        flex-direction: column !important;
        align-items: flex-end;
        gap: 8px;
    }

    .actions > div, .actions > div > button {
        width: 100%;
    }
    .page-content .grid > article,
    .page-content .grid > .half {
        grid-column: 1 / -1 !important;
    }
}

@media screen and (max-width: 400px) {
    /*.page-content .grid > article {*/
    /*    grid-column: 1 / -1;*/
    /*}*/
}


/* BODY CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.mob-menu-opened .toggle-mob-menu svg {
    transform: rotate(180deg);
}

.mob-menu-opened .admin-menu {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .collapsed .page-header {
        width: 40px;
    }

    .collapsed .page-header .admin-menu li > * {
        padding: 10px;
    }

    .collapsed .page-header .logo,
    .collapsed .page-header .admin-menu span,
    .collapsed .page-header .admin-menu .menu-heading {
        display: none;
    }

    .collapsed .page-header .admin-menu svg {
        margin-right: 0;
    }

    .collapsed .page-header .collapse-btn svg {
        transform: rotate(180deg);
    }

    .collapsed .page-content {
        left: 40px;
        width: calc(100% - 40px);
    }
}


/* SWITCH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.switch label {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    align-items: center;
    justify-content: flex-start;
}

.switch span:first-child {
    position: relative;
    width: 50px;
    height: 26px;
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    background: var(--switch-bgLightModeColor);
    transition: all 0.3s;
}

.switch span:first-child::before,
.switch span:first-child::after {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.switch span:first-child::before {
    top: 1px;
    left: 1px;
    width: 24px;
    height: 24px;
    background: var(--white);
    z-index: 1;
    transition: transform 0.3s;
}

.switch span:first-child::after {
    top: 50%;
    right: 8px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%);
    background: var(--switch-sunColor);
    box-shadow: 0 0 4px 2px #ffdb1a;
}

.switch [type="checkbox"]:checked + label span:first-child {
    background: var(--switch-bgDarkModeColor);
}

.switch [type="checkbox"]:focus + label span:first-child {
    box-shadow: 0 3px 5px rgba(255, 255, 255, 0.25);
}

.switch [type="checkbox"]:checked + label span:first-child::before {
    transform: translateX(24px);
}

.switch [type="checkbox"]:checked + label span:first-child::after {
    left: 12px;
    width: 15px;
    height: 15px;
    background: transparent;
    box-shadow: -2px -5px 0 var(--switch-moonColor);
    transform: translateY(-50%) rotate(-72deg);
}


/* LIGHT MODE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.light-mode {
    --page-header-bgColor: #f1efec;
    --page-header-bgColor-hover: #b9e4e0;
    --page-header-txtColor: #2c303a;
    --page-header-headingColor: #979595;
    --page-content-bgColor: #fff;
    --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.25);
}

.light-mode .page-header .admin-menu a:hover,
.light-mode .page-header .admin-menu a:focus,
.light-mode .page-header .admin-menu button:hover,
.light-mode .page-header .admin-menu button:focus {
    color: var(--black);
}

.light-mode .page-header .logo img,
.light-mode .page-header .admin-menu a:hover svg,
.light-mode .page-header .admin-menu a:focus svg,
.light-mode .page-header .admin-menu button:hover svg,
.light-mode .page-header .admin-menu button:focus svg {
    fill: var(--black);
}

.light-mode .switch [type="checkbox"]:focus + label span:first-child {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 767px) {
    .light-mode .search-and-user .admin-profile svg {
        fill: var(--black);
    }
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.page-footer a {
    margin-left: 4px;
}


/* Data Block
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.data-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid var(--gray-color);
    padding: var(--inner-padding);
    border-radius: var(--border-radius);
    cursor: pointer;
}

.data-block:hover {
    color: var(--white);
    background-color: var(--primary-color);
}

.data-block.selected {
    color: var(--white);
    background-color: var(--primary-color);
}

.data-block .data-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.data-block:after {
    content: "\203A";
    font-size: 38px;
}


/* Data Info
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.half {
    grid-column: span 2 / span 4;
}

.fluid {
    grid-column: 1 / -1;
}

.grid article header .actions {
    display: flex;
    flex-direction: row;
    gap: 8px;
}


/* Button
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.btn-primary {
    color: var(--primary-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-color);
    padding: 10px;
}

.btn-primary:hover {
    color: var(--white);
    background-color: var(--primary-color);
}

.btn-info {
    color: var(--info-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--info-color);
    padding: 10px;
}

.btn-info:hover {
    color: var(--white);
    background-color: var(--info-color);
}

.btn-gray {
    color: var(--gray-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--gray-color);
    padding: 10px;
}

.btn-gray:hover {
    color: var(--white);
    background-color: var(--gray-color);
}

.btn-danger {
    color: var(--red);
    border-radius: var(--border-radius);
    border: 1px solid var(--red);
    padding: 10px;
}

.btn-danger:hover {
    color: var(--white);
    background-color: var(--red);
}

.btn-success {
    color: var(--success-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--success-color);
    padding: 10px;
}

.btn-success:hover {
    color: var(--white);
    background-color: var(--success-color);
}

.btn-warning {
    color: var(--warning-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--warning-color);
    padding: 10px;
}

.btn-warning:hover {
    color: var(--white);
    background-color: var(--warning-color);
}


/* Margins
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.mt-1 {
    margin-top: 12px;
}

.mt-2 {
    margin-top: 18px;
}

.mt-3 {
    margin-top: 24px;
}


/* Borders
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.border-bottom {
    border-bottom: 1px solid var(--gray-color);
}

.flex-row .border-bottom:last-child {
    border-bottom: none;
}

.border {
    border: 1px solid var(--black);
}

.border-black {
    border: 1px solid var(--black);
    border-radius: 18px;
}

.border-circle {
    width: 0;
    height: 0;
    padding: 12px;
    position: relative;
    border-radius: 100% !important;
}

.border-rect {
    width: 0;
    height: 0;
    padding: 12px;
    position: relative;
}

.border-circle *, .border-rect * {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}


/* Flex Layouts
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.gap-3 {
    gap: 24px;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}


/* Grid Layouts
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.d-grid {
    display: grid;
}

/* Padding
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.p-1 {
    padding: 12px;
}
.p-2 {
    padding: 18px;
}
.p-3 {
    padding: 24px;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.line-wrapper {
    width: 100%;
    height: 3px;
    background-image: url("assets/brand_line.svg");
    background-size: auto 100%;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-sticky {
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.position-0 {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.gray-cover {
    opacity: 0;
    cursor: pointer;
}

.gray-cover:hover {
    color: var(--white);
    background-color: var(--gray-color);
    opacity: 0.85;
    visibility: visible;
}

.answer-choice {
    cursor: pointer;
}

.answer-choice:hover {
    color: var(--white);
    background-color: var(--black);
}

.answer-choice:hover .border-circle {
    border-color: var(--white) !important;
}

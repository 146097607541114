:root {
    --primary-color: #707BFC;
    --secondary-color: #6484FC;
    --info-color: #2CABF5;
    --gray-color: #D5CDCDFF;
    --success-color: #495E00;
    --warning-color: #E0B94F;
}

.bg-dark {
    background-color: black;
}

.data-table-wrapper {
    overflow-x: auto;
    scrollbar-width: thin;
}

table.data-table {
    width: 100%;
    border-collapse: collapse;
    /*table-layout: fixed;*/
}

.data-table th, td {
    padding: 18px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    border-bottom: 1px solid var(--gray-color);
}

.data-table tbody td {
    cursor: pointer;
}

.data-table tbody th {
    background-color: var(--white)!important;
}

.data-table tbody > tr:hover {
    color: var(--white);
    background-color: var(--primary-color);
}

#btn-load-more {
    width: 100%;
}

.text-right {
    text-align: right;
}

input[type="checkbox"] {
    left: 0;
    position: relative;
}

input[type="checkbox"]:checked {
    color: var(--white);
    background-color: var(--primary-color);
    cursor: pointer;
}

input[type="checkbox"]:before {
    content: "\2714";
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

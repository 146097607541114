form {
    display: flex;
    flex-direction: column;
}

.form-control-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.form-control-wrapper:last-child {
    margin-top: 18px;
}

.form-control-wrapper .form-control {
    grid-column: 2 / -1;
}

input {
    border: 1px solid var(--gray-color);
    border-radius: var(--border-radius);
    padding: 8px;
}

textarea {
    resize: none;
    border: 1px solid var(--gray-color);
    border-radius: var(--border-radius);
    padding: 8px;
}

input[type="submit"] {
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding: 12px 24px;
    margin-top: 18px;
}
